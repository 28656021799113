import { useState, useEffect, useCallback } from 'react';
import config from '../../../config';

export const useAuth = () => {
  const [currentUserId, setCurrentUserId] = useState(null);
  
  const fetchUserId = useCallback(async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) return;

    try {
      const response = await fetch(`${config.baseUrl}/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setCurrentUserId(userData.id);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, []);

  useEffect(() => {
    fetchUserId();
  }, [fetchUserId]);

  return { currentUserId };
};