import React, { useState, useEffect } from "react";
import "./UserDetails.css";

const UserDetails = ({ user, totalTransactions, totalLends, totalBorrows }) => {
  const [lendPercentage, setLendPercentage] = useState(0);
  const [borrowPercentage, setBorrowPercentage] = useState(0);

  useEffect(() => {
    if (totalLends !== undefined && totalBorrows !== undefined) {
      const totalAmount = totalLends + totalBorrows;
      if (totalAmount > 0) {
        setLendPercentage((totalLends / totalAmount) * 100);
        setBorrowPercentage((totalBorrows / totalAmount) * 100);
      } else {
        setLendPercentage(0);
        setBorrowPercentage(0);
      }
    }
  }, [totalLends, totalBorrows]);

  const isKYCCompleted = true; // Assuming `kycCompleted` is a boolean in the `user` object

  return (
    <div className="user-details-card">
      <div className="user-details-header">
        <div className="user-photo-container">
          <img
            src={user.profilePhoto}
            alt="Profile"
            className="profile-photo"
          />
        </div>
        <div className="user-info-primary">
          <h3 className="user-username">{user.username}</h3>
          <p className="user-email">{user.email}</p>
          <div className="kyc-status-container">
            <span className="kyc-label">KYC Status:</span>
            {isKYCCompleted ? (
              <span className="kyc-status kyc-completed">
                <span className="kyc-check">✓</span> Verified
              </span>
            ) : (
              <button className="kyc-button">Complete KYC</button>
            )}
          </div>
        </div>
      </div>

      <div className="user-stats-container">
        <div className="stats-summary">
          <div className="stat-item">
            <span className="stat-label">Total Transactions</span>
            <span className="stat-value">{totalTransactions || 0}</span>
          </div>
        </div>

        <div className="transaction-visualization">
          <div className="pie-chart-wrapper">
            <div
              className="pie-chart"
              style={{
                background: `conic-gradient(
                  #f59e0b ${lendPercentage}%,
                  #10b981 0 ${lendPercentage + borrowPercentage}%,
                  #e5e7eb ${lendPercentage + borrowPercentage}% 100%
                )`,
              }}
            >
              <div className="pie-chart-inner"></div>
            </div>
          </div>

          <div className="pie-chart-legend">
            <div className="legend-item">
              <span className="legend-color lend"></span>
              <span className="legend-label">Lends:</span>
              <span className="legend-value">${totalLends.toFixed(2)}</span>
            </div>
            <div className="legend-item">
              <span className="legend-color borrow"></span>
              <span className="legend-label">Borrows:</span>
              <span className="legend-value">${totalBorrows.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
