import React, { memo } from "react";
import { Link } from "react-router-dom";

export const DealCard = memo(({ deal, getLogoUrl }) => {
  const parseCollateral = (collateral) => {
    if (collateral.startsWith("{") && collateral.endsWith("}")) {
      return collateral.slice(1, -1).split(",");
    }
    return [collateral];
  };

  return (
    <div className="deal-card">
      <div className="deal-info">
        <InfoRow
          label="Amount"
          value={`$${Number(deal.amount).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}
        />
        <InfoRow label="Duration" value={`${deal.duration} days`} />
        <InfoRow label="Interest" value={`${deal.interest}%`} />
        <InfoRow label="User" value={deal.user_name} />

        <div className="deal-row collateral-row">
          <span className="label bold-label">Collateral:</span>
          <div className="collateral-container">
            {parseCollateral(deal.collateral).map((collateral, idx) => (
              <div key={idx} className="collateral-item">
                <img
                  src={getLogoUrl(collateral)}
                  alt={collateral}
                  loading="lazy"
                  className="collateral-logo"
                />
                <span className="collateral-name">{collateral}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Link
        to={`/request/${deal.order_id}`}
        state={deal}
        className="action-button"
      >
        {deal.type.toLowerCase()}
      </Link>
    </div>
  );
});

// InfoRow component for DealCard
const InfoRow = ({ label, value }) => (
  <div className="deal-row">
    <span className="label bold-label">{label}:</span>
    <span className="value">{value}</span>
  </div>
);
