import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./Arena.css";
import config from "../../config";
import { DealCard } from "./components/DealCard";
import { SkeletonCard } from "./components/SkeletonCard";
import { ErrorState } from "./components/ErrorState";
import { FilterBar } from "./FilterBar";
import { useAuth } from "./hooks/useAuth";

const Arena = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [collateralLogos, setCollateralLogos] = useState([]);
  const [filters, setFilters] = useState({
    asset: "All assets",
    type: "borrow",
    duration: "All durations",
    interest: "All interests",
  });
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    error: null,
    retryCount: 0,
  });
  const { currentUserId } = useAuth();

  const fetchData = useCallback(async () => {
    setLoadingState((prev) => ({ ...prev, isLoading: true, error: null }));

    try {
      const [requests, logos] = await Promise.all([
        fetch(`${config.baseUrl}/v1/credita/requests`, {
          headers: { accept: "application/json" },
        }).then((res) => {
          if (!res.ok) throw new Error("Failed to fetch requests");
          return res.json();
        }),
        fetch("/data/cryptoData.json").then((res) => {
          if (!res.ok) throw new Error("Failed to fetch logos");
          return res.json();
        }),
      ]);

      const filteredRequests = requests.filter(
        (request) =>
          request.status === "WAITING_BORROW" ||
          request.status === "WAITING_LEND"
      );

      setCryptoData(
        filteredRequests.map((request) => ({
          ...request,
          type: request.type === "LEND" ? "BORROW" : "LEND",
        }))
      );
      setCollateralLogos(logos);
      setLoadingState((prev) => ({ ...prev, isLoading: false }));
    } catch (error) {
      setLoadingState((prev) => ({
        ...prev,
        isLoading: false,
        error: error.message,
        retryCount: prev.retryCount + 1,
      }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = useMemo(() => {
    let data = cryptoData;

    if (filters.asset !== "All assets") {
      data = data.filter((deal) => deal.collateral.includes(filters.asset));
    }

    if (filters.type) {
      const targetType =
        filters.type.toUpperCase() === "BORROW" ? "LEND" : "BORROW";
      data = data.filter((deal) => deal.type === targetType);
    }

    if (filters.duration !== "All durations") {
      data = data.filter((deal) => {
        const duration = parseInt(deal.duration, 10);
        switch (filters.duration) {
          case "<15 days":
            return duration < 15;
          case "15-30 days":
            return duration >= 15 && duration <= 30;
          case "30-60 days":
            return duration > 30 && duration <= 60;
          case ">60 days":
            return duration > 60;
          default:
            return true;
        }
      });
    }

    if (filters.interest !== "All interests") {
      data = data.filter((deal) => {
        const interest = parseFloat(deal.interest);
        switch (filters.interest) {
          case "0-5%":
            return interest >= 0 && interest < 5;
          case "5-10%":
            return interest >= 5 && interest <= 10;
          case "+10%":
            return interest > 10;
          default:
            return true;
        }
      });
    }

    if (currentUserId) {
      data = data.filter((deal) => deal.user_id !== currentUserId);
    }

    return data;
  }, [cryptoData, filters, currentUserId]);

  const updateFilter = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleRetry = useCallback(() => {
    if (loadingState.retryCount < 3) {
      fetchData();
    }
  }, [fetchData, loadingState.retryCount]);

  const getLogoUrl = useCallback(
    (collateral) => {
      const match = collateralLogos.find((crypto) =>
        crypto.name.toLowerCase().includes(collateral.toLowerCase())
      );
      return match?.img || "/default-logo.png";
    },
    [collateralLogos]
  );

  if (loadingState.error) {
    return (
      <ErrorState
        error={loadingState.error}
        onRetry={handleRetry}
        retryCount={loadingState.retryCount}
        isLoading={loadingState.isLoading}
      />
    );
  }

  return (
    <div className="arena-container">
      <FilterBar filters={filters} updateFilter={updateFilter} />

      <div className="arena-content">
        {loadingState.isLoading
          ? Array(5)
              .fill()
              .map((_, i) => <SkeletonCard key={i} />)
          : filteredData.map((deal, index) => (
              <DealCard key={index} deal={deal} getLogoUrl={getLogoUrl} />
            ))}
      </div>
    </div>
  );
};

export default Arena;
