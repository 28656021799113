import React from "react";

export const FilterBar = ({ filters, updateFilter }) => {
  const buttonTexts = {
    borrow: "Lend",
    lend: "Borrow",
  };

  return (
    <header className="arena-header">
      <div className="filter-section">
        <div className="type-filter">
          {["borrow", "lend"].map((type) => (
            <button
              key={type}
              className={`type-button ${filters.type === type ? "active" : ""}`}
              onClick={() => updateFilter("type", type)}
            >
              {buttonTexts[type]}
            </button>
          ))}
        </div>

        <div className="advanced-filters">
          <FilterSelect
            id="asset-filter"
            label="Collateral"
            value={filters.asset}
            onChange={(e) => updateFilter("asset", e.target.value)}
            options={[
              { value: "All assets", label: "All Assets" },
              { value: "BTC", label: "BTC" },
              { value: "ETH", label: "ETH" },
              { value: "SOL", label: "SOL" },
            ]}
          />

          <FilterSelect
            id="duration-filter"
            label="Duration"
            value={filters.duration}
            onChange={(e) => updateFilter("duration", e.target.value)}
            options={[
              { value: "All durations", label: "All Durations" },
              { value: "<15 days", label: "<15 days" },
              { value: "15-30 days", label: "15-30 days" },
              { value: "30-60 days", label: "30-60 days" },
              { value: ">60 days", label: ">60 days" },
            ]}
          />

          <FilterSelect
            id="interest-filter"
            label="Interest"
            value={filters.interest}
            onChange={(e) => updateFilter("interest", e.target.value)}
            options={[
              { value: "All interests", label: "All Interests" },
              { value: "0-5%", label: "0-5%" },
              { value: "5-10%", label: "5-10%" },
              { value: "+10%", label: "+10%" },
            ]}
          />
        </div>
      </div>
    </header>
  );
};

// FilterSelect component for reusability
const FilterSelect = ({ id, label, value, onChange, options }) => (
  <div className="filter-group">
    <label htmlFor={id} className="filter-label bold-label">
      {label}
    </label>
    <select id={id} value={value} onChange={onChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);
