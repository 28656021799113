import React, { useState, useEffect } from "react";
import "./UserPayment.css";

const UserPayment = ({ user }) => {
  const [payment, setPayment] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState("");

  useEffect(() => {
    if (user?.token) {
      fetchPaymentMethod();
    }
  }, [user?.token]);

  const fetchPaymentMethod = async () => {
    try {
      setIsLoading(true);
      setFetchError("");
      const response = await fetch(
        "https://credita-backend.vercel.app/v1/credita/user/payment-methods",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401)
          throw new Error("Unauthorized. Please log in again.");
        if (response.status === 404) {
          setPayment(null);
          return;
        }
        throw new Error("Failed to fetch payment method");
      }

      const data = await response.json();
      setPayment(data[0] || null); // Take first payment method only
    } catch (error) {
      setFetchError(error.message);
      console.error("Error fetching payment method:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();
    if (!iban.trim() || !bankName.trim()) {
      setErrorMessage("Please fill in both IBAN and Bank Name");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetch(
        "https://credita-backend.vercel.app/v1/credita/user/payment-methods",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ iban, bank_name: bankName }),
        }
      );

      if (!response.ok) {
        if (response.status === 401)
          throw new Error("Unauthorized. Please log in again.");
        throw new Error("Failed to add payment method");
      }

      const newPayment = await response.json();
      setPayment(newPayment);
      setShowForm(false);
      setIban("");
      setBankName("");
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error adding payment method:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePayment = async () => {
    if (!payment) return;

    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetch(
        `https://credita-backend.vercel.app/v1/credita/user/payment-methods/${payment.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401)
          throw new Error("Unauthorized. Please log in again.");
        if (response.status === 404)
          throw new Error("Payment method not found or does not belong to you");
        throw new Error("Failed to delete payment method");
      }

      // Since DELETE returns 204 No Content, no response body to parse
      setPayment(null);
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error deleting payment method:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-payment-container">
      <div className="payment-header">
        <h2>Payment Method</h2>
      </div>

      {isLoading && <div className="loading">Loading...</div>}

      {!isLoading && (
        <div className="payment-content">
          {fetchError && <p className="error-message">{fetchError}</p>}

          {!payment ? (
            <div className="no-payment">
              <p>No payment method added</p>
              {!showForm && (
                <button
                  className="action-btn add-btn"
                  onClick={() => setShowForm(true)}
                  disabled={isLoading}
                >
                  Add Payment Method
                </button>
              )}
            </div>
          ) : (
            <div className="payment-detail">
              <div className="payment-info">
                <p>
                  <strong>Bank Name:</strong> {payment.bank_name}
                </p>
                <p>
                  <strong>IBAN:</strong> {payment.iban}
                </p>
              </div>
              <button
                className="action-btn delete-btn"
                onClick={handleDeletePayment}
                disabled={isLoading}
              >
                Delete Payment Method
              </button>
            </div>
          )}

          {showForm && !payment && (
            <form className="payment-form" onSubmit={handleAddPayment}>
              <div className="form-group">
                <label htmlFor="bankName">Bank Name</label>
                <input
                  id="bankName"
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  placeholder="Enter Bank Name"
                  required
                  disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="iban">IBAN</label>
                <input
                  id="iban"
                  type="text"
                  value={iban}
                  onChange={(e) => setIban(e.target.value)}
                  placeholder="Enter IBAN"
                  required
                  disabled={isLoading}
                />
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <div className="form-actions">
                <button
                  type="submit"
                  className="action-btn submit-btn"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding..." : "Add Payment"}
                </button>
                <button
                  type="button"
                  className="action-btn cancel-btn"
                  onClick={() => setShowForm(false)}
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default UserPayment;
