import React from "react";
import { Route, Routes } from "react-router-dom";
import News from "../pages/News/News";
import Home from "../pages/Home/Home";
import Login from "../pages/Login";
import MasterLayout from "../pages/MasterLayout";
import MarketPlace from "../pages/MarketPlace/MarketPlace";
import UserAccount from "../components/UserAccount/UserAccount";
import RequestPage from "../components/Requests/RequestPage";
import AboutUs from "../components/AboutUs/AboutUs";
import ArenaDetails from "../components/ArenaCard/ArenaDetails/ArenaDetails";
import AdyenPayment from "../components/adyen/AdyenPayment";
import TransactionView from "../components/UserAccount/TransactionView/TransactionView";
import routes from "./routes.json";
import MetaMaskPayment from "../components/web3/MetaMaskPayment";

const PageRoutes = ({ userDetails }) => {
  return (
    <>
      <Routes>
        <Route path={routes.HOME} element={<MasterLayout />}>
          <Route index element={<Home />} />
          <Route path={routes.NEWS} element={<News />} />
          <Route path={routes.MARKETPLACE} element={<MarketPlace />} />
          <Route path={routes.USERACCOUNTS} element={<UserAccount />} />
          <Route path={routes.REQUESTS} element={<RequestPage />} />
          <Route path={routes.ABOUTUS} element={<AboutUs />} />
          <Route path={routes.ARENA_DETAIL} element={<ArenaDetails />} />
          <Route path={routes.PAYMENT} element={<AdyenPayment />} />
          <Route path={routes.METAMASK} element={<MetaMaskPayment />} />
          {/* Add the Transaction Detail Route */}
          <Route
            path={routes.TRANSACTION_DETAIL}
            element={<TransactionView transactions={[]} />} // Replace with dynamic fetching logic
          />
        </Route>
      </Routes>

      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
