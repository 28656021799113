import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import routes from "../../routes/routes.json";
import creditaLogo from "../../images/Primary color.svg";
import "./TopBar.css";

const TopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const getUserData = () => ({
    username: localStorage.getItem("username"),
    picture: localStorage.getItem("picture"),
  });

  const { username, picture } = getUserData();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get("access_token");
    const username = queryParams.get("username");
    const pictureUrl = queryParams.get("picture");
    const email = queryParams.get("email");

    if (accessToken && username && pictureUrl) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("username", username);
      localStorage.setItem("picture", pictureUrl);
      localStorage.setItem("email", email);
      navigate(routes.HOME);
    }
  }, [navigate]);

  const handleLogout = async () => {
    const token = localStorage.getItem("accessToken");

    if (!token) return navigate(routes.HOME);

    try {
      const response = await fetch(
        "https://credita-backend.vercel.app/logout",
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
          credentials: "include",
        }
      );

      localStorage.clear();
      setShowDropdown(false);
      navigate(routes.HOME);

      if (!response.ok) console.error("Logout failed:", response.statusText);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <Navbar expand="md" bg="light" className="topbar">
      <div className="container">
        <Navbar.Brand as={NavLink} to={routes.HOME}>
          <img
            src={creditaLogo}
            alt="Credita Logo"
            className="navbar-logo"
            style={{ height: "30px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            <Nav.Link
              as={NavLink}
              to={routes.MARKETPLACE}
              className={
                location.pathname === routes.MARKETPLACE ? "active" : ""
              }
            >
              Arena
            </Nav.Link>
            {username && (
              <Nav.Link
                as={NavLink}
                to={routes.REQUESTS}
                className={
                  location.pathname === routes.REQUESTS ? "active" : ""
                }
              >
                Requests
              </Nav.Link>
            )}
            <Nav.Link
              as={NavLink}
              to={routes.NEWS}
              className={location.pathname === routes.NEWS ? "active" : ""}
            >
              News
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to={routes.ABOUTUS}
              className={location.pathname === routes.ABOUTUS ? "active" : ""}
            >
              About Us
            </Nav.Link>
            {username ? (
              <div className="profile-container">
                <img
                  src={picture}
                  className="profile-photo"
                  alt={`${username}'s profile`}
                  onClick={() => setShowDropdown((prev) => !prev)}
                />
                {showDropdown && (
                  <div className="profile-dropdown">
                    <div
                      onClick={() => {
                        navigate(routes.USERACCOUNTS);
                        setShowDropdown(false);
                      }}
                    >
                      Profile
                    </div>
                    <div
                      onClick={() => {
                        handleLogout();
                        setShowDropdown(false);
                      }}
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Nav.Link
                as={NavLink}
                to={routes.LOGIN}
                className={location.pathname === routes.LOGIN ? "active" : ""}
              >
                Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default TopBar;
