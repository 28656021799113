import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ArenaDetails.css";

const ArenaDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const deal = location.state;

  const [cryptoData, setCryptoData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch("/data/cryptoData.json")
      .then((response) => response.json())
      .then((data) => setCryptoData(data))
      .catch((error) => {
        console.error("Error loading crypto data:", error);
      });
  }, []);

  const getLogoUrl = (collateral) => {
    const match = cryptoData.find((crypto) =>
      crypto.name.toLowerCase().includes(collateral.toLowerCase())
    );
    return match?.img || "/default-logo.png";
  };

  const handleApiCall = async () => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      navigate("/login");
      return;
    }

    const apiUrl =
      deal.type.toLowerCase() === "lend"
        ? "https://credita-backend.vercel.app/v1/credita/accept/borrow"
        : "https://credita-backend.vercel.app/v1/credita/accept/lend";

    const requestBody = {
      order_id: deal.order_id,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("API call successful:", result);

        if (deal.type.toLowerCase() === "borrow") {
          navigate("/matamask");
        } else {
          navigate("/payment", {
            state: { amount: deal.amount, orderId: deal.order_id },
          });
        }
      } else {
        console.error("API call failed:", response.statusText);
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error making API call:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  if (!deal) {
    return (
      <div className="error-message">
        Error: No details found for this request.
      </div>
    );
  }

  const renderAction = () => {
    if (deal.type.toLowerCase() === "lend") {
      return (
        <div className="action-container">
          <p>
            If you find the offer appealing, you can proceed with deploying the
            funds. Once the payment is successfully processed, you can monitor
            it in the orders section.
          </p>
          <button className="pay-button" onClick={handleApiCall}>
            Pay Now
          </button>
        </div>
      );
    } else if (deal.type.toLowerCase() === "borrow") {
      return (
        <div className="action-container">
          <p>
            Provide the required collateral, and the requested amount will be
            credited to your primary bank account within 6 hours.
          </p>
          <button className="pay-button" onClick={handleApiCall}>
            Deploy
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="arena-details-container">
      <div className="card-wrapper">
        <h2 className="arena-title">Request Details</h2>
        <table className="arena-details-table">
          <tbody>
            <tr>
              <th>User</th>
              <td>{deal.user_name}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>${deal.amount}</td>
            </tr>
            <tr>
              <th>Duration</th>
              <td>{deal.duration} months</td>
            </tr>
            <tr>
              <th>Interest (%)</th>
              <td>{deal.interest}%</td>
            </tr>
            <tr>
              <th>Collateral</th>
              <td>
                <img
                  src={getLogoUrl(deal.collateral)}
                  alt={deal.collateral}
                  className="collateral-logo"
                />
                {deal.collateral}
              </td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{deal.type.toLowerCase()}</td>
            </tr>
          </tbody>
        </table>
        {renderAction()}
      </div>
    </div>
  );
};

export default ArenaDetails;
