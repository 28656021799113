import React from "react";

export const ErrorState = ({ error, onRetry, retryCount, isLoading }) => (
  <div className="error-container">
    <p>Failed to load data: {error}</p>
    {retryCount < 3 && (
      <button className="retry-button" onClick={onRetry} disabled={isLoading}>
        {isLoading ? "Retrying..." : "Try Again"}
      </button>
    )}
  </div>
);
