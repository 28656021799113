import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./CryptoPrices.css";
import config from "../../config";

const SkeletonCard = () => (
  <div className="crypto-card">
    <div className="skeleton skeleton-image"></div>
    <div className="crypto-info">
      <div className="skeleton skeleton-text"></div>
      <div className="skeleton skeleton-text"></div>
      <div className="skeleton skeleton-text"></div>
    </div>
  </div>
);

const CryptoPrices = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    error: null,
    retryCount: 0,
  });
  const itemsPerPage = 6;

  const fetchCryptoData = useCallback(async () => {
    setLoadingState((prev) => ({ ...prev, isLoading: true, error: null }));
    try {
      const response = await fetch(`${config.baseUrl}/crypto-data`);
      if (!response.ok) {
        throw new Error("Failed to fetch crypto data");
      }
      const data = await response.json();
      setCryptoData(data.data);
      setLoadingState((prev) => ({ ...prev, isLoading: false }));
    } catch (error) {
      setLoadingState((prev) => ({
        ...prev,
        isLoading: false,
        error: error.message,
        retryCount: prev.retryCount + 1,
      }));
    }
  }, []);

  useEffect(() => {
    fetchCryptoData();
  }, [fetchCryptoData]);

  const handleNext = () => {
    if ((currentPage + 1) * itemsPerPage < cryptoData.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleRetry = () => {
    if (loadingState.retryCount < 3) {
      fetchCryptoData();
    }
  };

  const currentItems = useMemo(() => {
    return cryptoData.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
  }, [cryptoData, currentPage, itemsPerPage]);

  if (loadingState.error) {
    return (
      <div className="error-container">
        <p>Failed to load crypto data: {loadingState.error}</p>
        {loadingState.retryCount < 3 && (
          <button
            className="retry-button"
            onClick={handleRetry}
            disabled={loadingState.isLoading}
          >
            {loadingState.isLoading ? "Retrying..." : "Try Again"}
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="crypto-container">
      <div className="crypto-header">
        <h2>Top Movers</h2>
        <div className="crypto-navigation">
          <button
            className="crypto-arrow"
            onClick={handlePrev}
            disabled={currentPage === 0 || loadingState.isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width="20"
              height="20"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            className="crypto-arrow"
            onClick={handleNext}
            disabled={
              (currentPage + 1) * itemsPerPage >= cryptoData.length ||
              loadingState.isLoading
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              width="20"
              height="20"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="crypto-grid">
        {loadingState.isLoading
          ? Array(itemsPerPage)
              .fill()
              .map((_, i) => <SkeletonCard key={i} />)
          : currentItems.map((coin, index) => (
              <div key={index} className="crypto-card">
                <img src={coin.img} alt={`${coin.name} logo`} loading="lazy" />
                <div className="crypto-info">
                  <p className="crypto-name">{coin.name}</p>
                  <p className="crypto-price">{coin.price}</p>
                  <p
                    className="crypto-change"
                    style={{ color: coin.change > 0 ? "#16c784" : "#ea3943" }}
                  >
                    {coin.change > 0 ? "↑" : "↓"} {coin.change}%
                  </p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default CryptoPrices;
