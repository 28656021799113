import React from "react";
import "./FeedbackPopup.css";

const FeedbackPopup = ({ onClose }) => {
  const handleLinkClick = () => {
    if (onClose) {
      onClose(); // Close the popup
    }
    // The browser will handle the redirect when the user clicks the link
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <p>
          We value your feedback! Please take a moment to fill out our survey.
        </p>
        <a
          href="https://docs.google.com/forms/u/1/d/e/1FAIpQLScZDay5CGXzZtI_5FYdZx7KVBcqdzSv5l086I8D10vW4uRWQQ/viewform"
          target="_blank"
          rel="noopener noreferrer"
          className="popup-link"
          onClick={handleLinkClick} // Close popup on link click
        >
          Give Feedback
        </a>
        <button onClick={onClose} className="popup-close-btn">
          Close
        </button>
      </div>
    </div>
  );
};

export default FeedbackPopup;
