import React from "react";
import "./AboutUs.css";
import mathias from "../../images/mathias2.png";
import gokul from "../../images/gokul.png";
import linkedinIcon from "../../images/linkedin.png";
import xIcon from "../../images/xblack.png";

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="main-heading">
        <h2>
          Transparency and freedom to take control of your financial future.
        </h2>
        <h4>Flexible peer-to-peer lending and borrowing solutions</h4>
        <div className="heading-border"></div>
      </div>

      <div className="about-content">
        <div className="text-column">
          <p>
            We believe in freedom, especially financial freedom. Credita is
            built on the idea that lending and borrowing should be simple,
            flexible, and accessible. Whether you are using cryptocurrencies,
            NFT’s, or traditional currency, you have the freedom to define your
            own terms.
          </p>
          <p>
            No credit score, no rigid rules, not an institution asking what,
            why, how–just a platform where users set the conditions that work
            best for them.
          </p>
        </div>
        <div className="text-column">
          <p>
            Today, more than 1.7 billion people are excluded from access to
            credit because of strict requirements, regulations, high fees, and
            outdated processes. We think financial systems shouldn’t limit
            people’s opportunities.
          </p>
          <p>
            Our main goal is to bring together people from all financial
            backgrounds–those familiar with crypto and those rooted in
            traditional systems– onto a single platform.
          </p>
        </div>
      </div>

      <h2 className="people-heading">People</h2>
      <div className="people-content">
        <div className="person-card">
          <img src={mathias} alt="Matias Sanchez" className="profile-pic" />
          <h4>Matias Sanchez Cordova</h4>
          <p className="person-role">CEO & Founder</p>
          <div className="social-links">
            <a
              href="https://www.linkedin.com/in/matias-sanchez-cordova-890821233/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
            </a>
            <a
              href="https://x.com/matias_sanchezc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xIcon} alt="X" className="social-icon" />
            </a>
          </div>
          <p className="person-bio">
            I founded Credita because I saw opportunities slip through my
            fingers countless times–whether in business, education, or personal
            growth–simply because I couldn’t access a loan. Despite having
            valuable digital assets, the barriers everyone, but especially young
            people face, like rigid credit requirements and lack of income
            history, were always in my way. I don’t want anyone else to go
            through the same struggle, which is why I’m committed to building a
            platform that ensures financial freedom for everyone.
          </p>
        </div>

        <div className="person-card">
          <img src={gokul} alt="Gokul Menon" className="profile-pic" />
          <h4>Gokul Menon</h4>
          <p className="person-role">CTO & Founder</p>
          <div className="social-links">
            <a
              href="https://www.linkedin.com/in/gokul-m-9a18b09b/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
            </a>
            <a
              href="https://x.com/gokulmenons"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={xIcon} alt="X" className="social-icon" />
            </a>
          </div>
          <p className="person-bio">
            I'm a hobbyist dev in a garage. I founded Credita with a bold vision
            to revolutionize the credit landscape. Blockchain space is a network
            governed without kings, priests, elites, or governments. It’s
            governed by anyone with merits to the network, and I’m ready to play
            my part. As a first-generation immigrant, I have faced the issue of
            not getting credit in a new country without an entity that
            determines my credit score. I have felt the pain of credit barriers,
            and I am committed to reshaping the credit landscape through
            technology and ingenuity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
