import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "./MetaMaskPayment.css";

const MetaMaskPayment = () => {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [amount, setAmount] = useState("");
  const [collateralType, setCollateralType] = useState("ETH");
  const [error, setError] = useState("");
  const [txHash, setTxHash] = useState("");

  // Credita wallet address (replace with your actual wallet address)
  const CREDITA_WALLET = "0xYourCreditaWalletAddressHere";

  const fetchBalance = async (address) => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const balanceWei = await provider.getBalance(address);
      const balanceEth = ethers.formatEther(balanceWei);
      setBalance(Number(balanceEth).toFixed(4));
    } catch (err) {
      setError("Failed to fetch balance: " + err.message);
    }
  };

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        await fetchBalance(accounts[0]);
        setError("");
      } else {
        setError("Please install MetaMask to use this feature");
      }
    } catch (err) {
      setError("Failed to connect to MetaMask: " + err.message);
    }
  };

  const handleTransfer = async (e) => {
    e.preventDefault();
    if (!account) {
      setError("Please connect your wallet first");
      return;
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      const tx = {
        to: CREDITA_WALLET,
        value: ethers.parseEther(amount),
      };

      const transaction = await signer.sendTransaction(tx);
      setTxHash(transaction.hash);
      setError("");

      await transaction.wait();
      alert(
        `Collateral transfer successful! Transaction hash: ${transaction.hash}`
      );
      await fetchBalance(account);
      setAmount("");
    } catch (err) {
      setError("Transfer failed: " + err.message);
    }
  };

  useEffect(() => {
    if (account) {
      fetchBalance(account);
    }
  }, [account]);

  return (
    <div className="payment-container">
      <div className="payment-card">
        <h2 className="payment-title">Credita Collateral Transfer</h2>

        {!account ? (
          <button className="connect-button" onClick={connectWallet}>
            Connect MetaMask Wallet
          </button>
        ) : (
          <div className="wallet-details">
            <div className="account-info">
              <span className="label">Connected Account:</span>
              <span className="value">
                {account.substring(0, 6)}...
                {account.substring(account.length - 4)}
              </span>
            </div>
            <div className="account-info">
              <span className="label">Balance:</span>
              <span className="value">
                {balance ? `${balance} ETH` : "Loading..."}
              </span>
            </div>

            <form onSubmit={handleTransfer} className="transfer-form">
              <div className="form-field">
                <label className="form-label">Collateral Type</label>
                <select
                  value={collateralType}
                  onChange={(e) => setCollateralType(e.target.value)}
                  className="form-select"
                >
                  <option value="ETH">ETH</option>
                  <option value="USDT">USDT (Coming Soon)</option>
                  <option value="USDC">USDC (Coming Soon)</option>
                </select>
              </div>

              <div className="form-field">
                <label className="form-label">Amount ({collateralType})</label>
                <input
                  type="number"
                  step="0.001"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="0.1"
                  className="form-input"
                  required
                />
              </div>

              <div className="form-field">
                <label className="form-label">Destination</label>
                <div className="destination-info">
                  Collateral will be transferred to Credita Wallet:{" "}
                  {CREDITA_WALLET.substring(0, 6)}...
                  {CREDITA_WALLET.substring(CREDITA_WALLET.length - 4)}
                </div>
              </div>

              <button type="submit" className="transfer-button">
                Transfer Collateral
              </button>
            </form>
          </div>
        )}

        {error && <div className="error-text">{error}</div>}
        {txHash && (
          <div className="success-text">
            Transaction successful! Hash:{" "}
            <a
              href={`https://etherscan.io/tx/${txHash}`}
              target="_blank"
              rel="noopener noreferrer"
              className="tx-link"
            >
              {txHash.substring(0, 10)}...
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default MetaMaskPayment;
