import React from "react";

export const SkeletonCard = () => (
  <div className="skeleton-card">
    {Array(5)
      .fill()
      .map((_, i) => (
        <div key={i} className="skeleton-item">
          <div className="skeleton skeleton-text"></div>
        </div>
      ))}
  </div>
);
